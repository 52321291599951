import React from "react";

import { Link } from "gatsby";
import { useSiteMetadata } from "@/hooks";

import { Image } from "@/components/Image";

import * as styles from "./Header.module.scss";

const Header = () => {
  const { author } = useSiteMetadata();
  return (
    <div className={styles.header}>
    <Link to="/">
      <Image alt={author.name} path={author.photo} className={styles.photo} />
    </Link>
    <h4 className={styles.name}>
      <a className={styles.name} href="/">{author.name}</a>
    </h4>
    </div>
  );
};

export default Header;