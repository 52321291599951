import React from "react";

import { Link } from "gatsby";

import { useSiteMetadata } from "@/hooks";
import { Icon } from "@/components/Icon";
import { ICONS } from "@/constants";
import { getContactHref, getIcon } from "@/utils";

import { Image } from "@/components/Image";

import * as styles from "./Author.module.scss";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles.author}>
      <div className={styles.main}>
        <Link to="/">
          <Image alt={author.name} path={author.photo} className={styles.photo} />
        </Link>

        <p className={styles.bio}>
          {author.bio}
        </p>
      </div>

      <div className={styles.social}>
        <ul className={styles.list}>
      {(Object.keys(author.contacts) as Array<keyof typeof ICONS>).map((name) =>
        author.contacts[name] ? (
          <li className={styles.item} key={name}>
            <a
              className={styles.link}
              href={getContactHref(name, author.contacts[name])}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name={name} icon={getIcon(name)} />
            </a>
          </li>
        ) : null,
      )}
    </ul>
      </div>
    </div>
  );
};

export default Author;
