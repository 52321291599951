import React from "react";

import { useSiteMetadata } from "@/hooks";

import * as styles from "./Footer.module.scss";

const Footer = () => {
  const { copyright } = useSiteMetadata();
  return(
    <div className={styles.footer}>
      <p className={styles.copyright}>{copyright}</p>
    </div>
  );
};

export default Footer;